import _ from 'lodash'

import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetGroupIdGroupToGroupMappingResponse,
  V1RidaApiGetGroupIdResponse,
  V1RidaApiGetGroupResponse,
} from './Type'

class Group {
  public static async getAllGroups(filterOptions?: {
    pageNumber?: number
    itemsPerPage?: number
    search?: string
  }) {
    const response = await RidaApiService.get<V1RidaApiGetGroupResponse>(
      'v1/group',
      {
        items_per_page: filterOptions?.itemsPerPage,
        page_number: filterOptions?.pageNumber,
        search: filterOptions?.search,
      },
    )

    return response.data.data
  }

  public static async getAllGroupMappings(
    groupId: string,
    filterOptions?: {
      pageNumber?: number
      itemsPerPage?: number
      search?: string
      getAllGroupDoingDeliveryForThisGroup?: boolean
    },
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetGroupIdGroupToGroupMappingResponse>(
        `v1/group/${groupId}/groupToGroupMapping`,
        {
          items_per_page: filterOptions?.itemsPerPage,
          page_number: filterOptions?.pageNumber,
          search: filterOptions?.search,
          get_all_groups_doing_delivery_for_this_group:
            filterOptions?.getAllGroupDoingDeliveryForThisGroup,
        },
      )

    return response.data.data
  }

  public static async updateGroupToGroupMappingConfig(
    groupToGroupMappingId: string,
    config: {
      driverEarning?: {
        default: {
          value: string | null
          type: string
          min_earned?: string | null
        }
      }
      ownerEarning?: {
        default: {
          value: string | null
          type: string
          min_earned?: string | null
        }
      }
      latePenalty: {
        enabled: boolean
        amountPerStopCharge: {
          value: string
          type: string
        }
        maxAmountPerStopCanCharge: {
          value: string
          type: string
        }
        gracePeriodInMinute: string
        pickupBufferInMinute: string
      }
      assignmentConfig: {
        isAutoAssignEnabled: boolean
      }
      jobOfferConfig: {
        sendOffer: {
          enabled: boolean
        }
      }
    },
  ) {
    await RidaApiService.put(
      `v1/groupToGroupMapping/${groupToGroupMappingId}/config`,
      {
        earning_config: {
          earning_per_stop: {
            driver_earning: config.driverEarning,
            owner_earning: config.ownerEarning,
          },
          late_penalty: {
            enabled: config.latePenalty.enabled,
            amount_per_stop_charge: config.latePenalty.amountPerStopCharge,
            max_amount_per_stop_can_charge:
              config.latePenalty.maxAmountPerStopCanCharge,
            grace_period_in_minute: _.parseInt(
              config.latePenalty.gracePeriodInMinute,
            ),
            pickup_buffer_in_minute: _.parseInt(
              config.latePenalty.pickupBufferInMinute,
            ),
          },
        },
        assignment_config: {
          is_auto_assign_enabled: config.assignmentConfig.isAutoAssignEnabled,
        },
        job_offer_config: {
          send_offer: config.jobOfferConfig.sendOffer,
        },
      },
    )
  }

  public static async removeGroupToGroupMapping(groupToGroupMappingId: string) {
    await RidaApiService.delete(
      `v1/groupToGroupMapping/${groupToGroupMappingId}`,
    )
  }

  public static async createGroupToGroupMapping(
    fromGroupIds: string[],
    toGroupIds: string[],
    config: {
      driverEarning?: {
        default: {
          value: string | null
          type: string
          min_earned?: string | null
        }
      }
      ownerEarning?: {
        default: {
          value: string | null
          type: string
          min_earned?: string | null
        }
      }
      latePenalty: {
        enabled: boolean
        amountPerStopCharge: {
          value: string
          type: string
        }
        maxAmountPerStopCanCharge: {
          value: string
          type: string
        }
        gracePeriodInMinute: string
        pickupBufferInMinute: string
      }
      assignmentConfig: {
        isAutoAssignEnabled: boolean
      }
      jobOfferConfig: {
        sendOffer: {
          enabled: boolean
        }
      }
    },
  ) {
    await RidaApiService.post('v1/groupToGroupMapping', {
      from_group_id: fromGroupIds,
      to_group_id: toGroupIds,
      earning_config: {
        late_penalty: {
          enabled: config.latePenalty.enabled,
          amount_per_stop_charge: config.latePenalty.amountPerStopCharge,
          max_amount_per_stop_can_charge:
            config.latePenalty.maxAmountPerStopCanCharge,
          grace_period_in_minute: _.parseInt(
            config.latePenalty.gracePeriodInMinute,
          ),
          pickup_buffer_in_minute: _.parseInt(
            config.latePenalty.pickupBufferInMinute,
          ),
        },
        earning_per_stop: {
          driver_earning: config.driverEarning,
          owner_earning: config.ownerEarning,
        },
      },
      assignment_config: {
        is_auto_assign_enabled: config.assignmentConfig.isAutoAssignEnabled,
      },
      job_offer_config: {
        send_offer: config.jobOfferConfig.sendOffer,
      },
    })
  }

  public static async createGroup(
    name: string,
    code: string,
    options: {
      enableSelfManagedOnPlatform: boolean
      serviceFee: string | null
    },
  ) {
    await RidaApiService.post('v1/group', {
      name,
      code,
      options: {
        enable_self_managed_on_platform: options.enableSelfManagedOnPlatform,
        service_fee: options.serviceFee,
      },
    })
  }

  public static async getGroupById(groupId: string) {
    const response = await RidaApiService.get<V1RidaApiGetGroupIdResponse>(
      `v1/group/${groupId}`,
    )

    return response.data.data
  }

  public static async updateGroupById(
    groupId: string,
    updatedData: {
      name: string
      config: {
        earningConfig?: {
          earningPerStop?: {
            driverEarning?: {
              perTotalStops?: {
                value: string
                type: string
                totalStopFrom: number
                totalStopTo: number
              }[]
            }
            ownerEarning?: {
              perTotalStops?: {
                value: string
                type: string
                totalStopFrom: number
                totalStopTo: number
              }[]
            }
          }
        }
      } | null
    },
  ) {
    await RidaApiService.put(`v1/group/${groupId}`, {
      name: updatedData.name,
      config: {
        earning_config: {
          earning_per_stop: {
            driver_earning: {
              per_total_stops: _.map(
                updatedData.config?.earningConfig?.earningPerStop?.driverEarning
                  ?.perTotalStops,
                st => {
                  return {
                    value: st.value,
                    type: st.type,
                    total_stop_from: st.totalStopFrom,
                    total_stop_to: st.totalStopTo,
                  }
                },
              ),
            },
            owner_earning: {
              per_total_stops: _.map(
                updatedData.config?.earningConfig?.earningPerStop?.ownerEarning
                  ?.perTotalStops,
                st => {
                  return {
                    value: st.value,
                    type: st.type,
                    total_stop_from: st.totalStopFrom,
                    total_stop_to: st.totalStopTo,
                  }
                },
              ),
            },
          },
        },
      },
    })
  }
}

export { Group as GroupService }
