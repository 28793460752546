import { Dayjs } from 'dayjs'
import _ from 'lodash'

import { DEFAULT_FORMAT } from './Constant'
import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetAidaChatAvailableDriverResponse,
  V1RidaApiPostAidaChatOptimizeRouteResponse,
  V1RidaApiPostAidaChatPublishRouteResponse,
} from './Type'
import { UtilityService } from './Utility'

class AidaChat {
  public static async getAvailableDrivers(
    date: Dayjs,
    merchantGroupIds: string[],
    driverIds: string[],
  ) {
    const response =
      await RidaApiService.get<V1RidaApiGetAidaChatAvailableDriverResponse>(
        'v1/aidaChat/availableDriver',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          merchant_group_id: merchantGroupIds,
          driver_id: driverIds,
        },
      )

    return response.data.data
  }

  public static async publishRoutes(routeData: {
    groupId: string
    date: Dayjs
    routes: {
      driverId: string | null
      routeId?: string
      vehicleType: string
      pickupTime: string
      stops: {
        type: string
        address1: string
        address2: string
        location: {
          lat: string
          lng: string
        }
        postalCode: string
        serviceTime?: number
        name: string
        phone: string
        window: string
        items: {
          category: string
          customId: string
          notes: string
          size: number
        }[]
      }[]
    }[]
  }) {
    const r =
      await RidaApiService.post<V1RidaApiPostAidaChatPublishRouteResponse>(
        'v1/aidaChat/publishRoute',
        {
          group_id: routeData.groupId,
          date: routeData.date.format(DEFAULT_FORMAT.DATE),
          routes: _.map(routeData.routes, r => {
            return {
              driver_id: r.driverId,
              vehicle_type: r.vehicleType,
              pickup_time: r.pickupTime,
              route_id: r.routeId,
              stops: _.map(r.stops, st => {
                return {
                  type: st.type,
                  address1: st.address1,
                  address2: st.address2,
                  location: {
                    lat: st.location.lat,
                    lng: st.location.lng,
                  },
                  postal_code: st.postalCode,
                  service_time: st.serviceTime,
                  name: st.name,
                  phone: UtilityService.formatPhone(st.phone),
                  window: st.window,
                  items: _.map(st.items, i => {
                    return {
                      category: i.category,
                      custom_id: i.customId
                        ? i.customId.toString()
                        : i.customId,
                      notes: i.notes ? i.notes.toString() : i.notes,
                      size: i.size || 24,
                    }
                  }),
                }
              }),
            }
          }),
        },
      )

    return r.data.data
  }

  public static async optimizeRoute(routeData: {
    stops: {
      id: string
      location: {
        lat: string
        lng: string
      }
      stopTypeId: number
    }[]
  }) {
    const r =
      await RidaApiService.post<V1RidaApiPostAidaChatOptimizeRouteResponse>(
        'v1/aidaChat/optimizeRoute',
        {
          stops: _.map(routeData.stops, st => {
            return {
              id: st.id,
              location: {
                lat: st.location.lat,
                lng: st.location.lng,
              },
              stop_type_id: st.stopTypeId,
            }
          }),
        },
      )

    return r.data.data
  }

  public static async syncRoute(
    routes: {
      pickupTime: string
      vehicleType: string
      assignedDriverId: string | null
      stops: {
        referenceId: string
      }[]
    }[],
  ) {
    await RidaApiService.post('v1/aidaChat/syncRoute', {
      routes: _.map(routes, r => {
        return {
          pickup_time: r.pickupTime,
          vehicle_type: r.vehicleType,
          assigned_driver_id: r.assignedDriverId,
          stops: _.map(r.stops, st => {
            return {
              reference_id: st.referenceId,
            }
          }),
        }
      }),
    })
  }
}

export { AidaChat as AidaChatService }
