import { RidaApiService } from '../RidaApi'

class SassFleetOperatorNotification {
  public static async sendPushNotification(
    pushData: {
      title: string
      description: string
    },
    userIds: string[],
  ) {
    await RidaApiService.postSassFleetOperator('v1/notification/sendPush', {
      user_id: userIds,
      push_data: {
        title: pushData.title,
        description: pushData.description,
      },
    })
  }
}

export { SassFleetOperatorNotification as SassFleetOperatorNotificationService }
