import axios from 'axios'

import { RidaApiService } from './RidaApi'
import {
  V1RidaApiGetFileUploadCheckedImageResponse,
  V1RidaApiGetFileUploadUrlResponse,
  V1RidaApiGetTaskIdFileUploadResponse,
  V1RidaApiPostFileUploadDownloadPodCheckReportResponse,
} from './Type'
import { Dayjs } from 'dayjs'
import { DEFAULT_FORMAT } from './Constant'
import _ from 'lodash'

class FileUpload {
  public static async getFileUploadPresignedUrl(data: {
    taskId?: string
    userId?: string
    documentTypeId: number
    extension: string
  }) {
    const response =
      await RidaApiService.get<V1RidaApiGetFileUploadUrlResponse>(
        'v1/fileUpload/url',
        {
          task_id: data.taskId,
          document_type_id: data.documentTypeId,
          file_extension: data.extension,
          user_id: data.userId,
        },
      )

    return response.data.data
  }

  public static async updateFileUploadSuccess(
    fileUploads: {
      existingId: string
      newId: string
    }[],
  ) {
    await RidaApiService.put('v1/fileUpload/confirm', {
      file_uploads: _.map(fileUploads, f => {
        return {
          existing_file_upload_id: f.existingId,
          new_file_upload_id: f.newId,
        }
      }),
    })
  }

  public static async addNewFileUploadSuccess(fileUploadIds: string[]) {
    await RidaApiService.post('v1/fileUpload/url', {
      ids: fileUploadIds,
    })
  }

  public static async removeFileUpload(fileUploadId: string) {
    await RidaApiService.delete(`v1/fileUpload/${fileUploadId}`)
  }

  public static async uploadPresignedUrl(
    url: string,
    data: Blob,
    contentType: string,
  ) {
    await axios.put(url, data, {
      headers: {
        'Content-Type': contentType,
      },
    })
  }

  public static async getFileUploadCheckedImages(
    date: Dayjs,
    filter: {
      itemsPerPage: number
      pageNumber: number
      isReviewedByOps: boolean
      groupIds: string[]
    },
  ) {
    const r =
      await RidaApiService.get<V1RidaApiGetFileUploadCheckedImageResponse>(
        'v1/fileUpload/checkedImage',
        {
          items_per_page: filter.itemsPerPage,
          page_number: filter.pageNumber,
          is_reviewed_by_ops: filter.isReviewedByOps,
          date: date.format(DEFAULT_FORMAT.DATE),
          group_id: filter.groupIds,
        },
      )

    return r.data.data
  }

  public static async updateFileUploadCheckedImage(
    fileUploadIds: string[],
    isImageDetailsSufficient: boolean,
    isReviewedByOps: boolean,
  ) {
    await RidaApiService.put('v1/fileUpload/checkedImage', {
      ids: fileUploadIds,
      is_image_details_sufficient: isImageDetailsSufficient,
      is_reviewed_by_ops: isReviewedByOps,
    })
  }

  public static async downloadPodCheckReport(date: Dayjs, groupIds: string[]) {
    const response =
      await RidaApiService.post<V1RidaApiPostFileUploadDownloadPodCheckReportResponse>(
        'v1/fileUpload/downloadPodCheckReport',
        {
          date: date.format(DEFAULT_FORMAT.DATE),
          group_id: groupIds,
        },
      )

    return response.data.data
  }

  public static async getFileUploadsForTaskId(taskId: string) {
    const response =
      await RidaApiService.get<V1RidaApiGetTaskIdFileUploadResponse>(
        `v1/task/${taskId}/fileUpload`,
      )

    return response.data.data
  }
}

export { FileUpload as FileUploadService }
