import { RidaApiService } from '../RidaApi'

class SassFleetOperatorTask {
  public static async updateTaskDeliveryStatus(
    taskId: string,
    options: {
      taskDeliveryStatusTypeId: number
    },
  ) {
    await RidaApiService.putSassFleetOperator(
      `v1/task/${taskId}/deliveryStatus`,
      {
        task_delivery_status_type_id: options.taskDeliveryStatusTypeId,
      },
    )
  }

  public static async updateStopDetail(
    stopId: string,
    detail: {
      name?: string
      phoneNumber?: string
      deliveryInstruction?: string | null
      merchantReferenceNumber?: string | null
    },
  ) {
    await RidaApiService.putSassFleetOperator(`v1/stop/${stopId}/detail`, {
      name: detail.name,
      phone_number: detail.phoneNumber,
      delivery_instruction: detail.deliveryInstruction,
      merchant_reference_number: detail.merchantReferenceNumber,
    })
  }
}

export { SassFleetOperatorTask as SassFleetOperatorTaskService }
