import _ from 'lodash'

import { NODE_ENV } from '../services/Type'
import 'dotenv/config'

const defaultConfig = {
  FIREBASE: {
    API_KEY: process.env.NUXT_ENV_RIDA_DRIVER_APP_ADMIN_DASHBOARD_FIREBASE_API_KEY!,
    AUTH_DOMAIN: '',
    PROJECT_ID: '',
    STORAGE_BUCKET: '',
    MESSAGE_SENDER_ID: '',
    APP_ID: '',
    DATABASE_URL: '',
  },
  GOOGLE_MAP: {
    API_KEY: process.env.NUXT_ENV_RIDA_DRIVER_APP_ADMIN_DASHBOARD_GOOGLE_MAP_API_KEY!,
  },
  PUSHER: {
    API_KEY: process.env.NUXT_ENV_RIDA_DRIVER_APP_ADMIN_DASHBOARD_PUSHER_API_KEY!,
    CLUSTER: 'ap1',
  },
  RIDA: {
    BACKEND_URL: '',
    SASS_FLEET_OPERATOR_BACKEND_URL: '',
    AIDA_BACKEND_URL: '',
  },
  RIDA_PLATFORM: {
    WEB_URL: '',
  },
  TAG: {
    APP_VERSION: process.env.NUXT_ENV_RIDA_DRIVER_APP_ADMIN_DASHBOARD_DEPLOYMENT_TAG!,
    OS: 'OPS_WEB',
  }
}

export const config = {
  [NODE_ENV.DEVELOPMENT]: _.merge({}, defaultConfig, {
    FIREBASE: {
      PROJECT_ID: 'rida-driver-app-development',
      CLIENT_EMAIL:
        'firebase-adminsdk-zvq16@rida-driver-app-development.iam.gserviceaccount.com',
      AUTH_DOMAIN: 'rida-driver-app-development.firebaseapp.com',
      STORAGE_BUCKET: 'rida-driver-app-development.appspot.com',
      MESSAGING_SENDER_ID: '474327554294',
      APP_ID: '1:474327554294:web:375a9117453fc624b674d7',
    },
    RIDA: {
      BACKEND_URL: 'http://localhost:3101/ops',
      SASS_FLEET_OPERATOR_BACKEND_URL: 'http://localhost:3101/sassFleetOperator',
      AIDA_BACKEND_URL: 'https://aida-backend.rida.ai/staging_chat',
    },
    RIDA_PLATFORM: {
      WEB_URL: 'http://localhost:4001',
    },
  }),
  [NODE_ENV.TEST]: _.merge({}, defaultConfig),
  [NODE_ENV.STAGING]: _.merge({}, defaultConfig, {
    FIREBASE: {
      PROJECT_ID: 'rida-driver-app',
      CLIENT_EMAIL:
        'firebase-adminsdk-yw7v8@rida-driver-app.iam.gserviceaccount.com',
      AUTH_DOMAIN: 'rida-driver-app.firebaseapp.com',
      STORAGE_BUCKET: 'rida-driver-app.appspot.com',
      MESSAGING_SENDER_ID: '489554761165',
      APP_ID: '1:489554761165:web:57b3b9b443ba161632a084',
    },
    RIDA: {
      BACKEND_URL: 'https://driver-app-backend-staging.rida.ai/ops',
      SASS_FLEET_OPERATOR_BACKEND_URL: 'https://driver-app-backend-staging.rida.ai/sassFleetOperator',
      AIDA_BACKEND_URL: 'https://aida-backend.rida.ai/staging_chat',
    },
    RIDA_PLATFORM: {
      WEB_URL: 'https://staging-2021.rida.ai',
    },
  }),
  [NODE_ENV.PRODUCTION]: _.merge({}, defaultConfig, {
    FIREBASE: {
      PROJECT_ID: 'rida-driver-app-production',
      CLIENT_EMAIL:
        'firebase-adminsdk-hp29g@rida-driver-app-production.iam.gserviceaccount.com',
      AUTH_DOMAIN: 'rida-driver-app-production.firebaseapp.com',
      STORAGE_BUCKET: 'rida-driver-app-production.appspot.com',
      MESSAGING_SENDER_ID: '888485269196',
      APP_ID: '1:888485269196:web:fb9c6b92ab660341c5bc69',
    },
    RIDA: {
      BACKEND_URL: 'https://driver-app-backend.rida.ai/ops',
      SASS_FLEET_OPERATOR_BACKEND_URL: 'https://driver-app-backend.rida.ai/sassFleetOperator',
      AIDA_BACKEND_URL: 'https://aida-backend.rida.ai/chat',
    },
    RIDA_PLATFORM: {
      WEB_URL: 'https://app.rida.ai',
    },
  }),
}
